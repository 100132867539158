var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input_video" }, [
    _c("iframe", {
      attrs: {
        src: _vm.pathUrl,
        allowfullscreen: "true",
        width: "100%",
        height: _vm.height,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }