<template>
  <div class="input_video">
    <iframe :src="pathUrl" allowfullscreen="true" width="100%" :height="height"></iframe>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pathUrl: '',
      height: 0
    }
  },
  created () {
    this.height = window.innerHeight + 'px'
    this.pathUrl = this.$route.query.pathUrl
  }
}
</script>
